<template>
  <v-container fluid class="pa-0">
    <TasksCard :all_task="true" />
  </v-container>
</template>

<script>
import TasksCard from "@/components/tasks/List";
export default {
  name: "Tasks",
  components: { TasksCard }
};
</script>

<style scoped></style>
